import React from 'react'
import Layout from '../components/Layout'
import { Router } from '@reach/router'

const TestComponent = ({ data, id }) => {
  let translator = require('short-uuid')()
  let uuid = translator.fromUUID(id)
  return <div>{uuid}</div>
}

const Test = ({ data, pageContext }) => {
  console.log(pageContext)
  return (
    <Layout>
      <Router>
        {/* default path */}
        <TestComponent data={data} path="test/:id" language={pageContext.language} />
        {/* path for locale */}
        <TestComponent data={data} path={pageContext.language + '/test/:id'} language={pageContext.language} />
      </Router>
    </Layout>
  )
}

export default Test
